import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';

import Head from "../components/Head"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"

import "../styles/main.sass"

const Resume = () => (
  <ParallaxProvider>
    <div className='resume'>
      <Head />
      <Navigation />
      <div className="wrapper">
      <div className='content'>
        <section>
            <Parallax y={[70, -20]}>
                <h2>Experience</h2>
            </Parallax>
            <hr />
            <div className="period">
                <div className="time">
                    <h3>Oct 2023 - Present</h3>
                </div>
                <div className="description">
                    <h3>ForeFlight - iOS Developer</h3>
                    <ul>
                        <li>Part of the team developing the iOS application written in Objective-C, Swift and SwiftUI.</li>
                        <li>Writing tests, pull requests, code reviews and documentation.</li>
                        <li>Working closely with QAs.</li>
                    </ul>
                </div>
            </div>
            <div className="period">
                <div className="time">
                    <h3>Jun 2022 - Sep 2023</h3>
                </div>
                <div className="description">
                    <h3>Wawa Fertility - iOS Engineer</h3>
                    <ul>
                        <li>Responsible for the iOS Application built in SwiftUI, Combine, and GraphQL.</li>
                        <li>Collaborated in a cross-functional team, including product, design, and backend developers, providing technical guidance and delivering fast and high-quality solutions.</li>
                    </ul>
                </div>
            </div>
            <div className="period">
                <div className="time">
                    <h3>2019 - 2022</h3>
                </div>
                <div className="description">
                    <h3>Simple Feast - iOS Developer</h3>
                    <ul>
                        <li>Took over the iOS Application built with Swift using MVVC architecture.</li>
                        <li>Took over the Android Application built with Kotlin.</li>
                        <li>Participate in planning and implementing new features.</li>
                    </ul>
                </div>
            </div>
            <div className="period">
                <div className="time">
                    <h3>2018 - 2019</h3>
                </div>
                <div className="description">
                    <h3>Practio - Mobile Developer</h3>
                    <ul>
                        <li>Rewrote a birth control application in React Native.</li>
                        <li>Administrate application in both App Store and Play Store.</li>
                        <li>Assist in development for web application built in JavaScript/React.</li>
                        <li>Took part in sprint planning.</li>
                        <li>Participated in biweekly retrospective sessions.</li>
                        <li>Team up with a remote developer working with 7 hour time difference.</li>
                        <li>Learned and briefly worked with Node, MongoDB, Heroku and Express.</li>
                    </ul>
                </div>
            </div>
            <div className="period">
                <div className="time">
                    <h3>2016 - 2018</h3>
                </div>
                <div className="description">
                    <h3>Practio - iOS Developer</h3>
                    <p>Responsible for a birth control application where I:</p>
                    <ul>
                        <li>Developed the application from scratch in Swift.</li>
                        <li>Participated in the UX discussions for the application.</li>
                        <li>Wrote a birth control pill protection algorithm for the application based on the medical guidance given.</li>
                        <li>Collaborated with quality assurance experts in order to certify the application as a medical device.</li>
                    </ul>
                </div>
            </div>
            <div className="period">
                <div className="time">
                    <h3>2013 - 2020</h3>
                </div>
                <div className="description">
                    <h3>Nomada - Web Developer</h3>
                <p>Developed many websites with React, GatsbyJS or pure HTML/JavaScript.</p>
                </div>
            </div>
            <div className="period">
                <div className="time">
                    <h3>2014 - 2018</h3>
                </div>
                <div className="description">
                    <h3>Appstract Development - Software Developer</h3>
                <ul>
                    <li>Participated in the development of a Swift application called "Smagg"</li>
                    <li>Participated in the development of a Swift application called "Zenfit"</li>
                    <li>Developed and launched "Pop the block" a simple game made in Unity</li>
                    <li>Designed three other iOS and Android applications</li>
                </ul>
                </div>
            </div>
        </section>

        <section>
            <Parallax y={[10, -80]}>
                <h2>Education</h2>
            </Parallax>
            <hr />
            <div className="period">
                <div className="time">
                    <h3>2014 - 2016</h3>
                </div>
                <div className="description">
                <h3>MSc. in Software Development</h3>
                <p>IT University of Copenhagen</p>
                </div>
            </div>

            <div className="period">
                <div className="time">
                <h3>2008 - 2013</h3>
                </div>
                <div className="description">
                <h3>Bachelor in Digital Art</h3>
                <p>Tecnológico de Monterrey</p>
                </div>
            </div>
        </section>

        <section>
            <Parallax y={[30, -50]}>
                <h2>Other</h2>
            </Parallax>
            <hr />
            <div className="period">
                <div className="time">
                <h3>2012 - 2013</h3>
                </div>
                <div className="description">
                <h3>Brenderup Højskole</h3>
                <p>Volunteer job as a leader in recreational activities, designer, substitute teacher and office work.</p>
                </div>
            </div>
        </section>
      </div> 
        <Footer />
      </div>
    </div>
  </ParallaxProvider>
)

export default Resume